<template>
  <el-config-provider :locale="plocale">
    <router-view />
  </el-config-provider>

  <checkRulesDialog />
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import checkRulesDialog from './views/checkRulesDialog.vue'
import { ElConfigProvider } from 'element-plus'
import zh from 'element-plus/dist/locale/zh-cn.mjs'
import th from 'element-plus/dist/locale/th.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const plocale = ref(null)

watch(
  () => locale.value,
  val => {
    switch (val) {
      case 'zh':
        plocale.value = zh
        break
      case 'en':
        plocale.value = en
        break
      case 'th':
        plocale.value = th
        break
      default:
        plocale.value = zh
        break
    }
  },
  { immediate: true, deep: true },
)
</script>

<style lang="scss">
@import './styles/theme/index.css';
* {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
</style>
