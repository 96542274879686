// 单站监控
export default {
  path: '/SingleStationMonitor',
  component: () => import('views/Layout.vue'),
  children: [
    // {
    //   path: 'Homepage',
    //   name: 'SingleStationHomepage',
    //   component: () => import('views/singleSiteMonitor/homepage.vue'),
    //   meta: {
    //     _meauname: '单站首页',
    //     keepAlive: true,
    //   },
    // },
    {
      path: 'HomepageCover',
      name: 'SingleStationHomepageCover',
      component: () => import('views/singleSiteMonitor/homepage/index.vue'),
      meta: {
        _meauname: '首页大屏',
        keepAlive: true,
      },
    },
    {
      path: 'SingleStationFaultAlarm',
      name: 'SingleStationFaultAlarm',
      component: () =>
        import(
          /*webpackChunkName: "SingleStationFaultAlarm"*/ 'views/singleSiteMonitor/faultAlarm'
        ),
      meta: {
        _meauname: '单站故障告警',
        keepAlive: true,
      },
    },
    {
      path: 'SiteSettings',
      name: 'SiteSettings',
      children: [
        {
          path: 'DeviceManagement',
          name: 'DeviceManagement',
          component: () =>
            import(
              /*webpackChunkName: "DeviceManagement"*/ 'views/singleSiteMonitor/siteSettings/deviceManagement'
            ),

          meta: {
            _meauname: '设备管理',
            keepAlive: true,
          },
        },
        {
          path: 'BasicInformation',
          name: 'BasicInformation',
          component: () =>
            import(
              /*webpackChunkName: "BasicInformation"*/ 'views/singleSiteMonitor/siteSettings/basicInformation'
            ),
          meta: {
            _meauname: '基础信息',
            keepAlive: true,
          },
        },
        {
          path: 'ElectricityPriceManagement',
          name: 'ElectricityPriceManagement',
          component: () =>
            import(
              /*webpackChunkName: "ElectricityPriceManagement"*/ 'views/singleSiteMonitor/siteSettings/electricityPrice'
            ),
          meta: {
            _meauname: '电价管理',
            keepAlive: true,
          },
        },
        {
          path: 'MeterTimeManagement',
          name: 'MeterTimeManagement',
          component: () =>
            import(
              /*webpackChunkName: "MeterTimeManagement"*/ 'views/singleSiteMonitor/siteSettings/meterTimeManagement'
            ),
          meta: {
            _meauname: '电表时段',
            keepAlive: true,
          },
        },
        {
          path: 'SiteLog',
          name: 'SiteLog',
          component: () =>
            import(
              /*webpackChunkName: "SiteLog"*/ 'views/singleSiteMonitor/siteSettings/siteLogs'
            ),
          meta: {
            _meauname: '站点日志',
            keepAlive: true,
          },
        },
        {
          path: 'SecondPassword',
          name: 'SecondPassword',
          component: () =>
            import(
              /*webpackChunkName: "SecondPassword"*/ 'views/singleSiteMonitor/siteSettings/secondPassword'
            ),
          meta: {
            _meauname: '二次口令',
            keepAlive: true,
          },
        },
      ],
    },
    {
      path: 'EquipmentMonitor',
      name: 'SingleStationEquipmentMonitor',
      component: () =>
        import(
          /*webpackChunkName: "SingleStationEquipmentMonitor"*/ 'views/singleSiteMonitor/equipmentMonitor'
        ),
      meta: {
        _meauname: '单站设备监控',
        keepAlive: true,
      },
    },
    {
      path: 'SystemMonitoring',
      name: 'SystemMonitoring',
      children: [
        {
          path: 'PowerDistribution',
          name: 'PowerDistribution',
          component: () =>
            import(
              /*webpackChunkName: "PowerDistribution"*/ 'views/singleSiteMonitor/SystemMonitoring/PowerDistribution'
            ),
          meta: {
            _meauname: '配用电系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'SystemOverview',
          name: 'SystemOverview',
          component: () =>
            import(
              /*webpackChunkName: "DeviceManagement"*/ 'views/singleSiteMonitor/SystemMonitoring/SystemOverview/index'
            ),
          meta: {
            _meauname: '站点系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'ChargingSystem',
          name: 'ChargingSystem',
          component: () =>
            import(
              /*webpackChunkName: "DeviceManagement"*/ 'views/singleSiteMonitor/SystemMonitoring/ChargingSystem'
            ),
          meta: {
            _meauname: '充电系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'EnergyStorageSystem',
          name: 'EnergyStorageSystem',
          component: () =>
            import(
              /*webpackChunkName: "DeviceManagement"*/ 'views/singleSiteMonitor/SystemMonitoring/EnergyStorageSystem'
            ),
          meta: {
            _meauname: '储能系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'PhotovoltaicSystem',
          name: 'PhotovoltaicSystem',
          component: () =>
            import(
              /*webpackChunkName: "DeviceManagement"*/ 'views/singleSiteMonitor/SystemMonitoring/PhotovoltaicSystem'
            ),
          meta: {
            _meauname: '光伏系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'OtherLoadsSystem',
          name: 'OtherLoadsSystem',
          component: () =>
            import(
              /*webpackChunkName: "OtherLoadsSystem"*/ 'views/singleSiteMonitor/SystemMonitoring/FanSystem'
            ),
          meta: {
            _meauname: '其它负荷系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'FanSystem',
          name: 'FanSystem',
          component: () =>
            import(
              /*webpackChunkName: "FanSystem"*/ 'views/singleSiteMonitor/SystemMonitoring/FanSystem'
            ),
          meta: {
            _meauname: '风力系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'AirConditionSystem',
          name: 'AirConditionSystem',
          component: () =>
            import(
              /*webpackChunkName: "AirConditionSystem"*/ 'views/singleSiteMonitor/SystemMonitoring/FanSystem'
            ),
          meta: {
            _meauname: '空调系统概览',
            keepAlive: true,
          },
        },
        {
          path: 'VideoSurveillance',
          name: 'VideoSurveillance',
          component: () =>
            import(
              /*webpackChunkName: "VideoSurveillance"*/ 'views/singleSiteMonitor/SystemMonitoring/videoSurveillance'
            ),
          meta: {
            _meauname: '视频监控',
            keepAlive: true,
          },
        },
      ],
    },
    {
      path: 'CurveReport',
      name: 'CurveReport',
      component: () => import('views/singleSiteMonitor/CurveReport.vue'),
      meta: {
        _meauname: '曲线报表',
        keepAlive: true,
      },
    },
    {
      path: 'WiringDiagram',
      name: 'WiringDiagram',
      component: () =>
        import('views/singleSiteMonitor/wiringDiagram/index.vue'),
      meta: {
        _meauname: '拓扑图',
        keepAlive: true,
      },
    },
    {
      path: 'AirCondition',
      name: 'AirCondition',
      component: () => import('views/singleSiteMonitor/airCondition/index.vue'),
      meta: {
        _meauname: '系统图',
        keepAlive: true,
      },
    },
  ],
}
