import { typeOf, deepCopy } from '@/utils/utils'
import { getDevice, getController } from '@/server/device'

const replaceValue = (data, changeData) => {
  let changeValue = deepCopy(changeData)
  let result = data
  let arr = data.split('/')
  if (typeOf(changeValue) === 'array') {
    changeValue = changeValue.find(
      (item) => item.oldData === data || item.oldData === arr[0]
    )
  }
  if (changeValue) {
    if (changeValue.type === '设备') {
      if (arr[0] === changeValue.oldData) {
        arr[0] = changeValue.newData
        result = arr.join('/')
      }
    } else {
      changeValue.oldData === data ? (result = changeValue.newData) : null
    }
  }
  return result
}

const toReplaceData = (data, changeData = null, callback = null) => {
  let list = []
  const findAllDevice = (target) => {
    // 对照值/执行赋值
    let valueKey = ''

    if (
      target.type === '设备属性' ||
      target.valueType === '设备属性' ||
      target.type === '变量名'
    ) {
      if (target.value) valueKey = 'value'
      if (target.iot_name) valueKey = 'iot_name'
      if (
        target.iot_name &&
        target.value &&
        typeOf(target.iot_name) == 'string' &&
        typeOf(target.value) == 'string'
      ) {
        valueKey = 'value'
        // delete target.iot_name
      }
    } else if (target.iot_name) {
      if (
        target.type !== '数值' &&
        target.valueType !== '数值' &&
        target.type !== '数值'
      )
        valueKey = 'iot_name'
    }
    if (valueKey) {
      if (callback) {
        callback({
          ...target.deviceInfo,
          value: target[valueKey],
        })
      } else {
        if (typeOf(target[valueKey]) === 'string') {
          if (!changeData) {
            list.push({
              ...target.deviceInfo,
              value: target[valueKey],
            })
          } else {
            // 过滤掉rootEvcsCluster设备
            if (!target.deviceInfo.label.includes('rootEvcsCluster')) {
              target[valueKey] = replaceValue(target[valueKey], changeData)
            }
          }
        }
      }
    }

    let arr = []
    if (typeOf(target) === 'array') arr = arr.concat(target)
    if (target.children) arr = arr.concat(target.children)
    if (target.controlValue) arr = arr.concat(target.controlValue)
    if (target.value && typeOf(target.value) === 'array')
      arr = arr.concat(target.value)

    if (target.applyTrue)
      arr = arr.concat(target.applyTrue.concat(target.applyFalse))
    if (
      target.type === '逻辑运算' ||
      Object.keys(target).includes('applyTrue')
    ) {
      if (target.value) findAllDevice(target.value)
    }
    arr.forEach((item) => {
      if (item) findAllDevice(item)
    })
  }
  if (data) findAllDevice(data)
  let map = new Map()
  list.forEach((item) => {
    if (!map.has(item.value)) {
      map.set(item.value, item)
    }
  })
  return list
}

const getAllDevice = (props) => {
  let list = []
  //设备查询
  const deviceParams = {
    tag: 'energy_subsystem',
    value: props.parent_id,
    gateway: props.gateway,
    flat_result: 1,
    include_subdevice: 1,
    include_details: 1,
    exclude_col: 'item_iot_tag',
  }

  return new Promise(async (resolve, reject) => {
    let data = []
    let isFlag = false

    let setData = () => {
      const index = data.findIndex((item) => item.item_iot_id === props.iot_id)
      if (index >= 0) {
        const current = data.splice(index, 1)
        data.unshift(current[0])
      }
      list = data
        .filter((item) => item.item_iot_productkey)
        .map((item) => {
          return {
            label: item.item_name,
            value: `${item.item_iot_productkey}#${item.item_iot_devicename}`,
            productkey: item.item_iot_productkey,
            category: item.item_iot_categoryname,
            extra_content: item.item_extra_content,
            general_config: item.item_iot_general_config,
            iot_id: item.item_iot_id,
            type: item.type,
            station_id: item.station_id,
            grid_id: item.grid_id,
            cell_id: item.cell_id,
            id: item.id,
          }
        })
      list.push({
        label: 'rootEvcsCluster',
        value: 'rootEvcsCluster',
        productkey: 'h9wbjpAH17U',
        category: 'EvcsCluster',
      })
      list.push({
        label: 'clockProvider',
        value: 'clockProvider',
        productkey: '',
        category: '',
      })
      list.push({
        label: '_componentManager',
        value: '_componentManager',
        productkey: '',
        category: '',
      })
      resolve(list)
    }
    getDevice(deviceParams).then((res) => {
      if (isFlag) {
        data = data.concat(
          res.data.msg.map((item) => {
            return {
              ...item,
              type: 'device',
            }
          })
        )
        setData()
      } else {
        data = res.data.msg.map((item) => {
          return {
            ...item,
            type: 'device',
          }
        })
        isFlag = true
      }
    })

    const controllerParams = {
      tag: 'energy_subsystem',
      value: props.parent_id,
      gateway: props.gateway,
      include_details: 1,
      exclude_col: 'item_iot_tag',
    }

    getController(controllerParams).then((controllerRes) => {
      if (isFlag) {
        data = data.concat(
          controllerRes.data.msg.map((item) => {
            return {
              ...item,
              type: 'controller',
            }
          })
        )
        setData()
      } else {
        data = controllerRes.data.msg.map((item) => {
          return {
            ...item,
            type: 'controller',
          }
        })
        isFlag = true
      }
    })
    // console.timeEnd('logic')
  })
}

const hasNeedReplaceDevice = async (data, devices) => {
  const list = toReplaceData(data)
  const findOne = list.find((item) => {
    let iot_name = item.value.split('/')[0]
    let findSame = devices.find((val) => val.value === iot_name)
    return !findSame
  })
  return !!findOne
}

const hasDisable = (value) => {
  let isFlag = false
  try {
    const getDisable = (data) => {
      if (typeOf(data) === 'object') {
        if (
          data.disabled &&
          data.type &&
          data.type !== '运算符' &&
          data.value !== '='
        ) {
          throw new Error('发现了disable')
        } else {
          for (let key in data) {
            getDisable(data[key])
          }
        }
      } else if (typeOf(data) === 'array') {
        for (let item of data) {
          getDisable(item)
        }
      }
    }
    getDisable(value)
  } catch (error) {
    isFlag = true
  }
  return isFlag
}

export {
  replaceValue,
  toReplaceData,
  getAllDevice,
  hasNeedReplaceDevice,
  hasDisable,
}
