<template>
  <div class="common-layout" ref="conte">
    <el-container style="height: 100%; overflow: hidden; position: relative">
      <el-header>
        <div class="fcsb" :key="locale">
          <div class="fcl left">
            <div class="logoLeft fcc">
              <div class="log fssb fcc">
                <img :src="dataJson.logoImg.url" />
                <div class="line"></div>
                <div class="systerm fcolumncenter">
                  <div>{{ specialTranslate(['systemMenu', 'menuListData'], dataJson.system_name) }}</div>
                  <!-- <div class="font-size:16px">qhww system</div> -->
                </div>
              </div>
            </div>

            <div class="iconfont Switch upmenu width100" :class="[start ? 'icon-shouqi' : 'icon-zhankai']" @click="Toggle" />
            <div v-if="MenuList.length">
              <el-menu
                class="el-menu-demo headerMenu"
                mode="horizontal"
                :ellipsis="false"
                :default-active="SelectData.path"
                text-color="#fff"
                active-text-color="#ffd04b"
              >
                <el-menu-item class="upmenu" v-for="(el, index) in MenuList" :key="index" :index="el.path" @click="handleSelect(el)">
                  <i :class="el.meta.icon" class="iconfont" style="font-size: 16px; margin-right: 10px" />
                  <template #title>
                    <span :key="locale">{{ specialTranslate(['systemMenu', 'menuListData'], el.meta._meauname) }}</span>
                  </template>
                </el-menu-item>
              </el-menu>
            </div>
          </div>
          <!-- 帮助文档 -->
          <!-- <HelpDoc></HelpDoc> -->
          <div class="middle-operations">
            <el-badge :is-dot="!!errorListCom && !!errorListCom.length && !!errorListCom.find(item => !item.isViewed)" class="dot-item">
              <el-icon style="font-size: 20px; color: #fff; cursor: pointer; z-index: 999" @click="showErrorList"
                ><InfoFilled style="font-size: 20px"
              /></el-icon>
            </el-badge>
          </div>
          <div class="fcr right signOut" v-if="SelectData.lives && SelectData.lives.condition">
            <span style="padding-right: 10px; color: #fff">{{ SelectData.lives.condition.condition }}/{{ SelectData.lives.condition.realFeel }}℃</span>

            <el-tooltip placement="bottom">
              <template #content>
                城市：{{ SelectData.lives.city.pname + SelectData.lives.city.name }}
                <br />
                天气状况：{{ SelectData.lives.condition.condition }}
                <br />
                实时气温：{{ SelectData.lives.condition.realFeel }}
                <br />
                空气湿度：{{ SelectData.lives.condition.humidity }}
                <br />
                风力级别：{{ SelectData.lives.condition.windLevel }}
                <br />
                风向：{{ SelectData.lives.condition.windDir }}
                <br />
              </template>
              <i :class="SelectData.icon" class="iconfont icon" v-if="SelectData.icon" />
            </el-tooltip>
            <!-- <el-switch v-model="theme" @change="changeTheme" /> -->
            <el-tooltip :content="specialTranslate(['systemMenu', 'menuListData'], '换肤')">
              <div @click="changeTheme" class="user">
                <i class="iconfont icon-qiehuan"></i>
              </div>
            </el-tooltip>
            <el-tooltip :content="specialTranslate(['systemMenu', 'menuListData'], '全屏')">
              <div class="user" @click="quanping">
                <i class="iconfont icon-fangda" style="font-size: 14px"></i>
              </div>
            </el-tooltip>
            <!-- 多语言切换 -->
            <el-dropdown @command="switchLanguage">
              <div class="user" style="margin-top: 4px">
                <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1.2em" height="1.2em">
                  <path
                    fill="currentColor"
                    d="m18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301a14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z"
                  />
                </svg>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :style="currentLanguage === 'zh' ? 'color: #66a190; background-color: #eff6f4' : ''" command="zh">中文</el-dropdown-item>
                  <el-dropdown-item :style="currentLanguage === 'en' ? 'color: #66a190; background-color: #eff6f4' : ''" command="en">English</el-dropdown-item>
                  <el-dropdown-item :style="currentLanguage === 'th' ? 'color: #66a190; background-color: #eff6f4' : ''" command="th">ไทย</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-tooltip :content="specialTranslate(['systemMenu', 'menuListData'], '退出')">
              <div class="user" @click="logout">
                <i class="iconfont icon-a-tuichu1"></i>
              </div>
            </el-tooltip>
          </div>
          <el-tooltip :content="specialTranslate(['systemMenu', 'menuListData'], '退出')" v-else>
            <div class="user" @click="logout">
              <i class="iconfont icon-a-tuichu1"></i>
            </div>
          </el-tooltip>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 60px)">
        <el-aside width="collapse">
          <template v-if="SelectData.data.length">
            <Menu :is-collapse="start" :data-list="SelectData" :default-active="defaultActive" @changeSite="changeSite" :key="locale" />
          </template>
        </el-aside>
        <el-main class="loading-area">
          <app-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
  <el-drawer v-model="errorLogVisible" title="请求错误响应" :size="800">
    <div>
      <el-collapse>
        <el-collapse-item v-for="item in errorListCom" :key="item.key" :name="item.key">
          <template #title>
            <div class="error-item-title">
              <div class="title-item">
                <span>时间: </span>
                <span>{{ item.time }}</span>
              </div>
              <div class="title-item">
                <span>页面: </span>
                <span>{{ item.routeName }}</span>
              </div>
              <div class="title-item">
                <span>请求路径: </span>
                <span>{{ item.requestUrl }}</span>
              </div>
            </div>
          </template>
          <div class="content-list">
            <div class="content-item">
              <span>页面路径: </span>
              <p>{{ item.routePath }}</p>
            </div>
            <div class="content-item">
              <span>请求路径: </span>
              <p>{{ item.requestUrl }}</p>
            </div>
            <div class="content-item" v-if="item.requestData">
              <span>请求参数: </span>
              <p>{{ item.requestData }}</p>
            </div>
            <div class="content-item" v-if="item.error">
              <span>响应内容: </span>
              <p>{{ item.error }}</p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </el-drawer>
  <re-login v-model="showReloginVisible" @close="handleReloginClose"></re-login>
  <ImportStation
    ref="importStationRef"
    :siteId="activeStation.id"
    :siteName="activeStation.item_label"
    :extra_content="activeStation.item_extra_content"
    :aiData="activeStation.aiData"
  ></ImportStation>
</template>

<script setup>
import Menu from '@/components/layout/menu.vue'
import appMain from 'views/AppMain'
import HelpDoc from '@/components/layout/helpDoc'
import ReLogin from '@/components/layout/relogin'
import ImportStation from './system/components/importStation.vue'
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, reactive, computed, watch, watchEffect, unref, nextTick, getCurrentInstance, onUnmounted } from 'vue'
import API from '../server/index.js'
import { getSites, get_secondPassword } from '@/server/site'
import { api as fullscreen } from 'vue-fullscreen'
import { FullScreen, InfoFilled } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { deepMergeObject, domainid, uuid } from 'utils/utils'
import useStorage from '@/hooks/storage'
import { specialTranslate } from '@/language/hooks.js'
import { useI18n } from 'vue-i18n'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const { getStorage, setStorage, removeStorage } = useStorage()
const FullscreenState = ref()
const store = useStore()
const theme = ['default', 'black', 'green'] //主题切换
let index = 0
// 全屏
const quanping = () => {
  fullscreen.toggle(document.querySelector('#app'), {
    teleport: true,
    callback: isFullscreen => {
      FullscreenState.value = isFullscreen
      // console.log(isFullscreen)
    },
  })
}
const changeTheme = () => {
  // console.log(index)
  if (index < theme.length - 1) {
    index++
    setStorage('theme', index)
    document.documentElement.setAttribute('theme', theme[index])
  } else {
    index = 0
    setStorage('theme', index)
    document.documentElement.removeAttribute('theme')
  }
}
const MenuList = ref([])
const start = ref(false)
const router = useRouter()
const route = useRoute()
const Toggle = () => {
  start.value = !start.value
}
const defaultActive = ref('')
const siteList = ref([])
const activeSiteId = ref()
const SelectData = reactive({
  path: '/System',
  data: [],
  icon: '',
  lives: {},
})
const getSiteInformation = computed(() => store.getters.getSiteInformation) //全部
//获取缓存
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    if (newValue != '/home') {
      let routeList = newValue.split('/').filter(item => item != '')
      SelectData.path = '/' + routeList[0]
      MenuList.value.forEach(item => {
        if (item.path == SelectData.path) {
          SelectData.data = item.children ? item.children : [item]
        }
      })
      defaultActive.value = newValue
    }
  },
  { immediate: true },
)
const setActiveSite = async () => {
  try {
    const res = await getSites({ include_hide: 0 })
    if (res.data && res.data.msg) {
      siteList.value = res.data.msg
      let activeId = getStorage('activeSiteId')
      if (activeId) {
        const findOne = siteList.value.find(item => item.id === activeId)
        if (!findOne) {
          activeId = siteList.value[0].id
          store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
        } else {
          store.dispatch('setSiteInformation', findOne) //储存站点信息
        }
      } else {
        activeId = siteList.value[0].id
        store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
      }
      activeSiteId.value = activeId
      store.dispatch('setActiveSiteId', activeId)
      setStorage('activeSiteId', activeId)
    }
  } catch (error) {
    console.log(error)
  }
}

watchEffect(async () => {
  const id = getSiteInformation.value.id
  if (id) {
    try {
      const res = await API.getWeather({
        application: 'ems',
        datasource: 'moji-weather',
        name: 'current_loc',
        body_param: {
          lat: getSiteInformation.value.item_latitude,
          lon: getSiteInformation.value.item_longitude,
        },
        query_param: {},
      })
      let data = res.data.data
      if (data) {
        SelectData.lives = data
        for (let item in iconWeatherMap) {
          if (iconWeatherMap[item].includes(data.condition.condition)) {
            SelectData.icon = item
            break
          }
        }
      }
    } catch (error) {
      console.log(error)
    }

    // //如果有站点id
    // reverseAnalysis({
    //   location: `${getSiteInformation.value.item_longitude},${getSiteInformation.value.item_latitude}`,
    // })
  } else {
    setActiveSite() //如果没有设置站点，则进行站点初始化
  }
})

const getMenus = async () => {
  const menulist = store.getters.getMenu
  MenuList.value = menulist
  let routeList = route.path.split('/').filter(item => item != '') //路由
  let Status = menulist.some(item => {
    return item.path == '/' + routeList[0]
  })
  if (Status) {
    //路由筛选项
    menulist.forEach(item => {
      if (item.path == '/' + routeList[0]) {
        SelectData.path = item.path
        SelectData.data = item.children ? item.children : [item]
      }
    })
  } else {
    menulist.forEach(item => {
      if (item.path == '/System') {
        SelectData.path = '/System'
        SelectData.data = item.children ? item.children : [item]
      }
    })
  }
  //二级路由
  defaultActive.value = route.path
}
const logout = async () => {
  const res = await API.logout() //退出系统
  store.dispatch('clearState')
  // reload
  router.go(0)
}
const handleSelect = item => {
  SelectData.path = item.path
  SelectData.data = item.children ? item.children : [item]
  router.push({
    path: SelectData.path + '/' + item.children[0].path,
  })
}
const iconWeatherMap = {
  'icon-wind': ['有风', '平静', '微风', '和风', '清风', '强风/劲风', '疾风', '大风', '烈风', '风暴', '狂爆风', '飓风', '热带风暴', '龙卷风'], //风
  'icon-weather-2': ['少云', '晴间多云', '多云'], //多云
  'icon-weather-16': ['雪', '阵雪', '小雪', '中雪', '大雪', '暴雪', '小雪-中雪', '中雪-大雪', '大雪-暴雪', '冷'], //雪
  'icon-weather-19': ['浮尘', '扬沙', '沙尘暴', '强沙尘暴', '雾', '浓雾', '强浓雾', '轻雾', '大雾', '特强浓雾'], //雾
  'icon-weather-1': ['晴', '热'], //晴
  'icon-yuxue': ['雨雪天气', '雨夹雪', '阵雨夹雪'], //雨夹雪
  'icon-yu': [
    '阵雨',
    '雷阵雨',
    '雷阵雨并伴有冰雹',
    '小雨',
    '中雨',
    '大雨',
    '暴雨',
    '大暴雨',
    '特大暴雨',
    '强阵雨',
    '强雷阵雨',
    '极端降雨',
    '毛毛雨/细雨',
    '雨',
    '小雨-中雨',
    '中雨-大雨',
    '大雨-暴雨',
    '暴雨-大暴雨',
    '大暴雨-特大暴雨',
    '冻雨',
  ], //雨
  'icon-weather-3': ['阴', '霾', '中度霾', '重度霾', '严重霾', '未知'], //阴
}

const init = async () => {
  await getMenus()
  // getMenu()
}
const dataJson = ref({
  logoImg: {
    url: '',
    fixedNumber: [4, 1],
  },
  iconImg: {
    url: '',
    fixedNumber: [1, 1],
  },
  system_name: '',
})
//查询
const query = async site_system => {
  const res = await get_secondPassword({
    // parent_id: domainid(),
    category: '系统管理',
    subcategory: '个性配置',
  })
  dataJson.value = {
    logoImg: {
      url: `${process.env.VUE_APP_OSS_BASE_URL}/cloud/images/%E9%9B%B6C%E4%BA%91logo%E9%80%8F%E6%98%8E1.png`,
      fixedNumber: [4, 1],
    },
    iconImg: {
      url: `${process.env.VUE_APP_OSS_BASE_URL}/zero_c_cloud/favicon.ico`,
      fixedNumber: [1, 1],
    },
    system_name: '系统名称',
  }
  if (res.data.code == 200) {
    try {
      let item_extra_content = '{}'
      if (res.data.msg.length) {
        item_extra_content = res.data.msg[0].item_extra_content
      }
      let dataObject = JSON.parse(item_extra_content)
      if (site_system && (site_system.system_name || (site_system.logoImg && site_system.logoImg.url) || (site_system.iconImg && site_system.iconImg.url))) {
        dataJson.value = deepMergeObject(unref(dataJson.value), site_system)
      } else {
        dataJson.value = deepMergeObject(unref(dataJson.value), dataObject)
      }
      setStorage('themeuseInfo', dataJson.value)
    } catch (error) {
      console.log(error)
    }
    changeFavicon()
  }
}
const changeFavicon = () => {
  let favicon = document.querySelector('link[rel="icon"]')
  if (favicon !== null) {
    favicon.href = dataJson.value.iconImg.url
  } else {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = dataJson.value.iconImg.url
    document.head.appendChild(favicon)
  }
}

// 更改站点
const changeSite = data => {
  query(data)
}

const errorLogVisible = ref(false)
const errorListCom = computed(() => store.getters.getResponseError)
// 展示错误日志
const showErrorList = () => {
  if (!errorListCom.value.length) return
  errorLogVisible.value = true
  store.dispatch(
    'setResponseError',
    errorListCom.value.map(item => {
      return {
        ...item,
        isViewed: true,
      }
    }),
  )
}

// 重新登录
const showReloginVisible = computed(() => store.getters.getShowRelogin)
const handleReloginClose = () => {
  store.dispatch('setShowRelogin', false)
}

const { locale } = useI18n()
const currentLanguage = ref(locale.value)
// 切换语言
function switchLanguage(val) {
  locale.value = val
  currentLanguage.value = val
  setStorage('language', val)
}

async function handleExportPDF(a_node, q_node) {
  // 使用html2canvas将内容转换为图像
  const q_canvas = await html2canvas(q_node, { scale: 2 })
  const q_imgdata = q_canvas.toDataURL('image/png')

  const a_canvas = await html2canvas(a_node, { scale: 2 })
  const a_imgdata = a_canvas.toDataURL('image/png')

  const pdfWrap = document.createElement('div')
  pdfWrap.className = 'pdf-preview-wrap'

  const questionTitle = document.createElement('h3')
  questionTitle.innerText = '咨询问题'
  const questionImg = document.createElement('img')
  questionImg.src = q_imgdata

  const answerTitle = document.createElement('h3')
  answerTitle.innerText = '回答建议'
  const answerImg = document.createElement('img')
  answerImg.src = a_imgdata

  pdfWrap.appendChild(questionTitle)
  pdfWrap.appendChild(questionImg)
  pdfWrap.appendChild(answerTitle)
  pdfWrap.appendChild(answerImg)
  document.body.appendChild(pdfWrap)

  nextTick(async () => {
    const pdf_canvas = await html2canvas(pdfWrap, { scale: 2 })
    const pdf_imgdata = pdf_canvas.toDataURL('image/png')
    // 将图像转换为PDF
    const pdf = new jsPDF('p', 'mm', 'a4')
    const imgProps = pdf.getImageProperties(pdf_imgdata)
    const pdfWidth = pdf.internal.pageSize.getWidth() - 20
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
    pdf.addImage(pdf_imgdata, 'PNG', 0, 0, pdfWidth, pdfHeight)
    // 保存PDF文件
    pdf.save(`${store.getters.getSiteInformation.item_label}.pdf`)
    document.body.removeChild(pdfWrap)
  })
}

const exportAiPdf = () => {
  const messageId = sessionStorage.getItem('itemnode_id')
  const messageBox = document.querySelector(`[nlux-message-id="${messageId}"]`)
  const questionBox = messageBox.parentNode.parentNode.parentNode.previousSibling.querySelector('.nlux-markdownStream-root')
  handleExportPDF(messageBox, questionBox)
}

const importStationRef = ref()
const activeStation = ref({})
const createStation = () => {
  const messageId = sessionStorage.getItem('itemnode_id')
  const messageBox = document.querySelector(`[nlux-message-id="${messageId}"]`)
  const tables = messageBox.getElementsByTagName('table')
  let trlist = tables[0].getElementsByTagName('tbody')[0].children
  trlist = [...trlist]
  function getNumbers(str) {
    const regex = /\d+/g
    return str.match(regex).map(Number)
  }
  let aiData = {
    type: '整站数据',
    station: {
      id: 'D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC',
      domain_id: 'W3I5xdDT1lNcACHWfxv',
      item_category: 'energy_subsystem',
      item_subcategory: '站点',
      item_label: '光储充',
      item_iot_tag: '{"tag_dev": "None", "energy_subsystem": ["D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC"]}',
      item_picture: '',
      item_latitude: 40.0379957559,
      item_longitude: 116.3502184469,
      item_description: '',
      item_hide: 0,
      parent_id: '',
      children: [
        {
          id: 'snQhdbDmQUm_5IDPejlDXg--__LQThCyUrLyrovMpU6wC',
          domain_id: domainid(),
          item_category: 'energy_subsystem',
          item_subcategory: '储能',
          item_label: '储能',
          item_iot_tag:
            '{"tag_dev": "None", "energy_subsystem": ["D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC", "dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC", "snQhdbDmQUm_5IDPejlDXg--__LQThCyUrLyrovMpU6wC"]}',
          item_picture: null,
          item_latitude: 0,
          item_longitude: 0,
          item_description: '',
          item_hide: 0,
          parent_id: 'dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC',
          devices: [],
        },
        {
          id: 'NHIFyiC9QuSMuKolKErl8w--__LQThCyUrLyrovMpU6wC',
          domain_id: domainid(),
          item_category: 'energy_subsystem',
          item_subcategory: '光伏',
          item_label: '光伏',
          item_iot_tag:
            '{"tag_dev": "None", "energy_subsystem": ["D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC", "dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC", "NHIFyiC9QuSMuKolKErl8w--__LQThCyUrLyrovMpU6wC"]}',
          item_picture: null,
          item_latitude: 0,
          item_longitude: 0,
          item_description: '',
          item_hide: 0,
          parent_id: 'dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC',
          devices: [],
        },
        {
          id: 'O3dDfeyjT_2Ah2wpx3dWXg--__LQThCyUrLyrovMpU6wC',
          domain_id: domainid(),
          item_category: 'energy_subsystem',
          item_subcategory: '充电桩',
          item_label: '充电桩',
          item_iot_tag:
            '{"tag_dev": "None", "energy_subsystem": ["D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC", "dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC", "O3dDfeyjT_2Ah2wpx3dWXg--__LQThCyUrLyrovMpU6wC"]}',
          item_picture: null,
          item_latitude: 0,
          item_longitude: 0,
          item_description: '',
          item_hide: 0,
          parent_id: 'dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC',
          devices: [],
        },
      ],
    },
  }
  trlist.forEach(tritem => {
    const tdlist = [...tritem.children]
    let categoryText = tdlist[0].innerText
    if (categoryText.includes('桩')) {
      const nums = getNumbers(tdlist[1].innerText)
      if (nums[0]) {
        const cell = aiData.station.children.find(item => item.item_subcategory === '充电桩')
        let UUID = uuid()
        if (cell) {
          cell.devices = cell.devices.concat(
            new Array(nums[0]).fill({}).map((pile, pileIndex) => {
              let pilename = `${categoryText}#${pileIndex + 1}`
              let pileno = `${UUID}${pileIndex + 1}`
              return {
                domain_id: domainid(),
                item_iot_categoryname: 'EVCP_V1G',
                item_iot_productname: 'EVCP-钛昕-AC',
                item_iot_productkey: 'i4uqaELWdNi',
                item_name: pilename,
                item_iot_general_config: {
                  alias: pilename,
                  parent_id: '',
                  state: [],
                  debugMode: false,
                  channelLimit: {},
                  initValue: {},
                  scaleFactor: {},
                  alternativeChannel: {},
                  errorRetryWaitSecond: 300,
                  workerRetryTimes: 0,
                  manualTag: 'disable_3rd_saas',
                },
                item_iot_protocal: null,
                item_iot_config: '{"host": "127.0.1.1", "port": 1031, "device_no": "70001"}',
                item_energy_meter: null,
                item_device_no: pileno,
                item_subdevice_no: '',
                item_parent_id: null,
                children: [1].map((gun, gunIndex) => {
                  let gunname = `${pilename}#枪0${pileIndex + 1}`
                  return {
                    domain_id: domainid(),
                    item_iot_categoryname: 'EVCS',
                    item_iot_productname: 'EVCS-链宇-智星堆',
                    item_iot_productkey: 'i4uqCd0fnsA',
                    item_name: gunname,
                    item_iot_general_config: {
                      alias: gunname,
                      parent_id: '',
                      state: [],
                      debugMode: false,
                      channelLimit: {},
                      initValue: {
                        MINIMUM_HARDWARE_CHARGE_POWER: 0,
                        MAXIMUM_HARDWARE_CHARGE_POWER: -7000,
                      },
                      scaleFactor: {},
                      alternativeChannel: {},
                      errorRetryWaitSecond: 300,
                      workerRetryTimes: 0,
                    },
                    item_iot_protocal: null,
                    item_iot_config:
                      '{"host": "127.0.1.1", "port": 1031, "version": {"version_list": [{"id": "509cd82b54d043068f43c14377a18f69", "name": "v1.0.0", "content": "{\\"host\\":\\"127.0.1.1\\",\\"port\\":1031,\\"device_no\\":\\"70001\\",\\"subdevice_no\\":\\"1\\"}", "version": "v1.0.0", "create_time": "2024-08-22 19:10:12"}], "current_version": "v1.0.0", "current_version_id": "509cd82b54d043068f43c14377a18f69"}, "device_no": "70001", "subdevice_no": "1"}',
                    item_iot_gateway: 'y7AIcHQHfnL3BGJlG70mi4uq00',
                    item_energy_meter: '{"is_active_meter":1}',
                    item_device_no: pileno,
                    item_subdevice_no: '01',
                  }
                }),
              }
            }),
          )
        }
      }
    } else if (categoryText.includes('光伏')) {
      const cell = aiData.station.children.find(item => item.item_subcategory === '光伏')
      let UUID = uuid()
      if (cell) {
        cell.devices.push({
          id: 'KYT0BpQ5QpKtQdSxsgvSEw--__KaihwjdIOieqvRsaAdB',
          domain_id: domainid(),
          item_iot_categoryname: 'PVDCDC',
          item_iot_productname: 'PVDCDC-链宇-30kWPTP',
          item_iot_productkey: 'i4uq29rBHM1',
          item_iot_id: 'svtADoIbrjTIEFH5gR06i4uq00',
          item_iot_status: 'OFFLINE',
          item_name: `${categoryText}#01`,
          item_iot_general_config: {
            alias: `${categoryText}#01`,
            parent_id: '',
            state: [
              {
                state_name: 'FAULT',
                logic_operator: 'OR',
                conditions: [
                  {
                    channel: 'STATE',
                    value: 3,
                    operator: '=',
                  },
                ],
                entry: [],
                exit: [],
                action: [],
              },
              {
                state_name: 'ALERT',
                logic_operator: 'OR',
                conditions: [
                  {
                    channel: 'STATE',
                    value: 2,
                    operator: '=',
                  },
                ],
                entry: [],
                exit: [],
                action: [],
              },
              {
                state_name: 'GO_RUNNING',
                logic_operator: 'AND',
                conditions: [],
                entry: [],
                exit: [],
                action_wait: 40,
                action_retry: 5,
                action_timeout: 300,
                action: [
                  {
                    channel: 'RUN_CONTROL_SET',
                    value: 1,
                    wait: 1,
                  },
                  {
                    channel: 'MPPT_POWER_REF_SET',
                    value: 10000,
                    wait: 1,
                  },
                  {
                    channel: 'MPPT_VOLTAGE_TURN_ON_SET',
                    value: 580000,
                    wait: 1,
                  },
                  {
                    channel: 'MPPT_VOLTAGE_TURN_OFF_SET',
                    value: 550000,
                    wait: 1,
                  },
                ],
              },
              {
                state_name: 'RUNNING',
                logic_operator: 'AND',
                conditions: [
                  {
                    channel: 'RUN_STATE',
                    value: 1,
                    tolerance: 0,
                    operator: '=',
                  },
                  {
                    channel: 'MPPT_POWER_REF',
                    value: [1000, 30000],
                    operator: 'range',
                  },
                  {
                    channel: 'MPPT_VOLTAGE_TURN_ON',
                    value: [200000, 800000],
                    operator: 'range',
                  },
                  {
                    channel: 'MPPT_VOLTAGE_TURN_OFF',
                    value: [200000, 800000],
                    operator: 'range',
                  },
                ],
                entry: [],
                exit: [],
                action: [],
              },
              {
                state_name: 'GO_STOPPED',
                logic_operator: 'AND',
                conditions: [],
                entry: [],
                exit: [],
                action_wait: 10,
                action_retry: 20,
                action_timeout: 300,
                action: [
                  {
                    channel: 'RUN_CONTROL_SET',
                    value: 0,
                    wait: 1,
                  },
                ],
              },
              {
                state_name: 'STOPPED',
                logic_operator: 'AND',
                conditions: [
                  {
                    channel: 'RUN_STATE',
                    value: 0,
                    operator: '=',
                  },
                ],
                entry: [],
                exit: [],
                action: [],
              },
            ],
            debugMode: false,
            channelLimit: {
              set_channel_limit: [
                {
                  channel: 'RUN_CONTROL_SET',
                  operator: 'in',
                  value: [0, 1, 2],
                },
                {
                  channel: 'PV_VOLTAGE_MAX_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'PV_VOLTAGE_MIN_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'BUS_VOLTAGE_MAX_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'BUS_VOLTAGE_MIN_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'PV_VOLTAGE_REF_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'BUS_VOLTAGE_REF_SET',
                  operator: 'range',
                  value: [200000, 830000],
                },
                {
                  channel: 'RESET_CONTROL_SET',
                  operator: 'in',
                  value: [0, 1, 2],
                },
                {
                  channel: 'MPPT_POWER_REF_SET',
                  operator: 'range',
                  value: [0, 300000],
                },
              ],
            },
            initValue: {
              MAXIMUM_HARDWARE_POWER: 30000,
              MINIMUM_HARDWARE_POWER: 1000,
            },
            scaleFactor: {},
            alternativeChannel: {},
            errorRetryWaitSecond: 300,
            workerRetryTimes: 0,
            virtualDevice: true,
            debugLogEndTime: '2024-04-23 18:23:17',
          },
          item_productivity: '{}',
          item_iot_devicename: 'KYT0BpQ5QpKtQdSxsgvSEw@iot',
          item_iot_protocal: null,
          item_iot_config: '{"host":"127.0.0.9","port":3234,"address":1}',
          item_iot_gateway: 'y7AIcHQHfnL3BGJlG70mi4uq00',
          item_energy_meter: '{"is_active_meter":1}',
          item_device_no: UUID,
          item_subdevice_no: '',
          item_parent_id: null,
          item_extra_content: {},
        })
      }
    } else if (categoryText.includes('储能')) {
      const cell = aiData.station.children.find(item => item.item_subcategory === '储能')
      let UUID = uuid()
      if (cell) {
        cell.devices.push({
          id: 'c55hJPeoR3W23jOwuzXvdg--__KaihwjdIOieqvRsaAdB',
          domain_id: 'W3I5xdDT1lNcACHWfxv',
          item_iot_categoryname: 'BMS',
          item_iot_productname: 'BMS-Grand-JND',
          item_iot_productkey: 'i4uqwFtpkXR',
          item_iot_id: 'VCZeYVI7W2dvAVf66Tsii4uq00',
          item_iot_status: 'ONLINE',
          item_name: `${categoryText}#01`,
          item_iot_create: null,
          item_iot_active: '2024-08-23 15:44:13',
          item_iot_tag:
            '{"tag_dev": "snQhdbDmQUm_5IDPejlDXg--__LQThCyUrLyrovMpU6wC", "energy_subsystem": ["D7-wexahRD6U-R-LIYGIZw--__LQThCyUrLyrovMpU6wC", "dZBo7TwhRR-NMOZ1LvR45Q--__LQThCyUrLyrovMpU6wC", "snQhdbDmQUm_5IDPejlDXg--__LQThCyUrLyrovMpU6wC"]}',
          item_iot_general_config: {
            alias: `${categoryText}#01`,
            parent_id: '',
            state: [],
            debugMode: false,
            channelLimit: {},
            initValue: {
              MAXIMUM_HARDWARE_CHARGE_POWER: -10000,
              MINIMUM_HARDWARE_CHARGE_POWER: 0,
              MAXIMUM_HARDWARE_DISCHARGE_POWER: 10000,
              MINIMUM_HARDWARE_DISCHARGE_POWER: 0,
              CAPACITY: 500000,
              SOC_TO_CHARGE_P: [
                [0.01, 1],
                [9, 1],
                [10, 1],
                [95, 1],
                [100, 0],
              ],
            },
            scaleFactor: {},
            alternativeChannel: {},
            errorRetryWaitSecond: 300,
            workerRetryTimes: 0,
          },
          item_productivity: '{}',
          item_iot_devicename: 'c55hJPeoR3W23jOwuzXvdg@iot',
          item_iot_protocal: null,
          item_iot_config:
            '{"host":"127.0.0.1","port":12,"address":1,"version":{"current_version_id":"191a110520d54d588618eebb3bc82e88","current_version":"v1.0.0","version_list":[{"id":"191a110520d54d588618eebb3bc82e88","name":"v1.0.0","version":"v1.0.0","content":"{\\"host\\":\\"127.0.0.1\\",\\"port\\":12,\\"address\\":1}","create_time":"2024-08-22 18:37:08"}]}}',
          item_iot_gateway: 'y7AIcHQHfnL3BGJlG70mi4uq00',
          item_energy_meter: '{"is_active_meter":1}',
          item_device_no: UUID,
          item_subdevice_no: null,
          item_parent_id: null,
          item_extra_content: {},
        })
      }
    }
  })

  console.log(aiData)
  // return

  const siteInfo = store.getters.getSiteInformation
  activeStation.value = siteInfo
  activeStation.value.aiData = JSON.stringify(aiData)

  nextTick(() => {
    importStationRef.value.createStationByAi()
  })
}

onUnmounted(() => {
  window.removeEventListener('exportAiPdf', exportAiPdf)
})

onMounted(async () => {
  window.addEventListener('exportAiPdf', exportAiPdf)
  window.addEventListener('createStation', createStation)
  setTimeout(() => {
    let userInfo = getStorage('userInfo')

    if (userInfo.user_info.user_name === 'linku_ai') {
      document.getElementsByClassName('webchat-bubble-tip')[0].style.display = 'block'
    } else {
      document.getElementsByClassName('webchat-bubble-tip')[0].style.display = 'none'
    }
  }, 2000)
  store.dispatch('createSocket')
  const themeType = {
    0: 'default',
    1: 'black',
    2: 'green',
  }
  if (getStorage('theme') !== null && getStorage('theme') !== undefined) {
    document.documentElement.setAttribute('theme', themeType[getStorage('theme')])
  } else {
    document.documentElement.setAttribute('theme', 'black')
  }
  await query()
  await init()
  if (getStorage('themeuseInfo').system_theme !== null && getStorage('themeuseInfo').system_theme !== undefined) {
    document.documentElement.setAttribute('theme', themeType[getStorage('themeuseInfo').system_theme])
    setStorage('theme', getStorage('themeuseInfo').system_theme)
  } else {
    document.documentElement.setAttribute('theme', 'black')
  }
})
</script>

<style lang="scss" scoped>
:deep(.el-header) {
  height: auto;
  --el-header-padding: 0;
  border-bottom: 1px solid var(--line-color);
  margin-bottom: 15px;
  background: var(--header-background-color);
}

.el-aside {
  border: 1px solid var(--line-color) !important;
  background: var(--header-background-color);
  // background: #1c1e24;
}
.signOut {
  background: var(--loginright-imgurl);
  height: 63px;
  width: 290px;
  background-size: cover;
}

.user {
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  i {
    margin-right: 7px;
  }
}
.fcsb {
  .logoLeft {
    height: 63px;
    // width: 416px;
    background: var(--logo-imgurl);
    background-size: 100% 100%;
  }
}

.el-main {
  padding: 0 !important;
  margin-left: 10px;
}
.icon {
  font-size: 40px;
  color: #fff;
  margin-right: 10px;
}
.left {
  .log {
    width: 300px;
    height: 35px;
    padding-right: 70px;
    img {
      height: 40px;
    }
    .line {
      margin: 0 10px;
      height: 15px;
      border: 1px solid #ffffff;
    }
    .systerm {
      // width: 77px;
      font-size: 10px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
    }
  }

  .Switch {
    margin-left: 23px;
    font-size: 16px;
    color: #fbffff;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }
}
.loading-area.lock-scroll {
  overflow: hidden;
  position: relative;
}
.middle-operations {
  display: flex;
  align-items: center;
  .dot-item {
    margin-top: 10px;
    margin-right: 40px;
  }
}
.error-item-title {
  display: flex;
  align-items: center;
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出容器宽度的内容 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  .title-item {
    margin-right: 15px;
    span {
      color: #000;
      &:first-of-type {
        color: #666;
      }
    }
  }
}
.content-list {
  .content-item {
    display: flex;
    align-items: center;
    span {
      width: 70px;
      color: #888;
    }
    p {
      flex: 1;
      line-height: 1.2;
    }
  }
}
</style>

<style lang="scss">
.pdf-preview-wrap {
  width: 800px;
  position: fixed;
  left: -1000px;
  top: 100px;
  z-index: 100000;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  h3 {
    margin: 20px 0 10px;
    color: #f00;
    &:first-of-type {
      margin-top: 0;
    }
  }
  img {
    width: 100%;
  }
}
</style>
